import { forwardRef, useEffect, useRef } from "react";
import { useRowSelect, useSortBy, useTable } from "react-table";
import TableHeader from "./tableHeader";

const defaultPropGetter = () => ({});
const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

export default function Table(props) {
  const {
    className,
    columns,
    data,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
    hooks = () => {},
    enableRowSelection = false,
    isSortable = true,
    currentSortKey,
    currentSortOrder,
    onSort,
    width,
    wordWrap,
  } = props;
  let additionalParams = [];
  if (enableRowSelection) {
    additionalParams = [
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      },
    ];
  }
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy,
      ...additionalParams,
      hooks
    );

  // Render the UI for your table
  return (
    <table
      id="table-to-xls"
      {...{
        ...getTableProps(),
        className: `table ${className}`,
      }}
    >
      <thead>
        {headerGroups.map((headerGroup, hgIndex) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={hgIndex}>
            {headerGroup.headers.map((header, index) => (
              <TableHeader
                key={index}
                column={header}
                clgIndex={index}
                allSortable={isSortable}
                onSort={onSort}
                currentSortKey={currentSortKey}
                currentSortOrder={currentSortOrder}
              />
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps(getRowProps(row, rowIndex))} key={rowIndex}>
              {row.cells.map((cell, cellIndex) => {
                return (
                  <td
                    key={`${rowIndex}${cellIndex}${cell.column.Header}`}
                    style={{
                      wordWrap: `${wordWrap}`,
                      width: cellIndex === 0 && `${width}`,
                    }}
                    {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                      getColumnProps(cell.column),
                      getCellProps(cell),
                    ])}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
