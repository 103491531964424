import { useEffect } from "react";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import firebaseModules from "../firebase/firebase";

const PrivateRoute = ({ children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = firebaseModules.currentUser();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login", { state: { from: location } });
    }
  }, [isAuthenticated, navigate, location]);

  return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
};

export default PrivateRoute;
