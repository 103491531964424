import create from "zustand";

export type useModalStoreType = {
  isOpen: boolean;
  content: any;
  closeModal: () => void;
  showModalContent: (content: any) => void;
};
export const useModalStore = create<useModalStoreType>((set) => {
  return {
    isOpen: false,
    content: null,
    closeModal: () => {
      set(() => ({
        isOpen: false,
      }));
    },
    showModalContent: (content: any) => {
      set(() => ({
        content: content,
        isOpen: true,
      }));
    },
  };
});
