import React from "react";

function CreditTag() {
  return (
    <div
      className="tag-container"
      style={{ backgroundColor: "#D9E6DC", color: "#439D62" }}
    >
      <img src="/images/arrowDownRight.svg" alt="" />
      Credit
    </div>
  );
}

export default CreditTag;
