import React from "react";
type Props = {
  currency: string;
  amount: number;
};
function TransactionsTableAmount(props: Props) {
  const { currency, amount } = props;
  function getCurrency(currency: string) {
    return currency === "INR" ? <>&#8377;</> : <>$</>;
  }
  return (
    <>
      {getCurrency(currency)}
      {Math.abs(amount)}
    </>
  );
}

export default TransactionsTableAmount;
