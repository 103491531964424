import ReferralsTableUpdatedOn from "../pages/dashboard/ReferralsTableUpdatedOn";
import TransactionsTableAmount from "../pages/transctions/TransactionsTableAmount";
import TransactionsTableCreatedOn from "../pages/transctions/TransactionsTableCreatedOn";
import TransactionsTableCreditDebitTags from "../pages/transctions/TransactionsTableCreditDebitTags";
export const transactionTableHeaders = [
  {
    Header: "TRANSACTION ID",
    accessor: "orderId",
    isSortable: true,
  },
  {
    Header: "DATE AND TIME",
    accessor: "createdOn",
    isSortable: true,
    Cell: ({ cell }) => {
      return <TransactionsTableCreatedOn cell={cell} />;
    },
  },
  {
    Header: "Details",
    accessor: "details",
    isSortable: false,
  },
  {
    Header: "",
    accessor: "type",
    isSortable: false,
    Cell: ({ cell }) => {
      return <TransactionsTableCreditDebitTags cell={cell} />;
    },
  },
  {
    Header: "AMOUNT",
    accessor: "change",
    isSortable: true,
    Cell: ({ cell }) => {
      return (
        <TransactionsTableAmount
          currency={cell.row.original.currency}
          amount={cell.row.original.change}
        />
      );
    },
  },
  {
    Header: "BALANCE",
    accessor: "balance",
    isSortable: true,
    Cell: ({ cell }) => {
      return (
        <TransactionsTableAmount
          currency={cell.row.original.currency}
          amount={cell.row.original.balance}
        />
      );
    },
  },
];
export const referralsTableHeaders = [
  {
    Header: "REFERRALS",
    accessor: "displayName",
    isSortable: true,
  },
  {
    Header: "EMAIL ID",
    accessor: "email",
    isSortable: true,
  },
  {
    Header: "PHONE NO",
    accessor: "phoneNo",
    isSortable: false,
  },
  {
    Header: "UPDATED ON",
    accessor: "updatedOn",
    isSortable: true,
    Cell: ({ cell }) => {
      return <ReferralsTableUpdatedOn cell={cell} />;
    },
  },
];
