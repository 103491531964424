import React, { useCallback } from "react";
import { postSubscriptionUpdate } from "../../clients/partnersClients";
import { useApiLoaderStore } from "../../store/apiLoderStore";
import { useToastStore } from "../../store/toastStore";
import { useTransctionStore } from "../../store/transactionsStore";
type Props = {
  userUid: string;
  productType: string;
  teacherCount: number;
  isRenew: boolean;
  closeModal: () => void;
  onSuccess: () => void;
};
function UpdateSubscriptionDialogue(props: Props) {
  const { userUid, productType, teacherCount, isRenew, closeModal, onSuccess } =
    props;
  const showLoader = useApiLoaderStore((state) => state.showLoader);
  const showToast = useToastStore((state) => state.showToast);
  const setPartnerSubscriptionUpdateResponse = useTransctionStore(
    (state) => state.setPartnerSubscriptionUpdateResponse
  );
  const onUpdateClicked = useCallback(() => {
    closeModal();
    const body = {
      userUid,
      productType,
      teacherCount,
      isRenew,
    };
    showLoader(true);
    postSubscriptionUpdate(body, showToast).then((response) => {
      showLoader(false);
      if (response) {
        setPartnerSubscriptionUpdateResponse(response);
        onSuccess();
      }
    });
  }, [
    userUid,
    productType,
    teacherCount,
    isRenew,
    showToast,
    showLoader,
    closeModal,
    onSuccess,
    setPartnerSubscriptionUpdateResponse,
  ]);
  return (
    <div className="modal-outer-container">
      <div className="modal-header-container">
        <label className="flex-center header">Update subscription</label>
        <img
          src="/images/add-class-x.svg"
          alt=""
          style={{ cursor: "pointer", height: "24px", width: "24px" }}
          onClick={closeModal}
        />
      </div>
      <div className="modal-desc">
        Are you sure want to update subscription?
      </div>
      <div className="modal-bottom-actionbar">
        <button className="button-v2 outline-btn" onClick={closeModal}>
          Close
        </button>
        <button type="submit" className="button-v2" onClick={onUpdateClicked}>
          Update
        </button>
      </div>
    </div>
  );
}

export default UpdateSubscriptionDialogue;
