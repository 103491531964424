import React from "react";
import DebitTag from "../../tags/DebitTag";
import CreditTag from "../../tags/CreditTag";
type Props = {
  cell: any;
};
function TransactionsTableCreditDebitTags(props: Props) {
  const { cell } = props;
  const amount = cell.row.original.change;
  function getTransactionTag(amount: number) {
    if (amount > 0) {
      return <CreditTag />;
    } else if (amount < 0) {
      return <DebitTag />;
    } else {
      return <></>;
    }
  }
  return <>{getTransactionTag(amount)}</>;
}

export default TransactionsTableCreditDebitTags;
