import { useState, useCallback } from "react";

export function useLocalStorage(key, initialValue, type) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      if (type === "object") {
        return item ? JSON.parse(item) : initialValue;
      }
      return item ?? initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value) => {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      type === "object"
        ? window.localStorage.setItem(key, JSON.stringify(valueToStore))
        : window.localStorage.setItem(key, valueToStore);
    },
    [setStoredValue, key, storedValue, type]
  );
  return [storedValue, setValue];
}
