import { Popover } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import firebaseModules from "../../firebase/firebase";
import PartnerTag from "../../tags/PartnerTag";

function NavBarRight() {
  const currentUser = firebaseModules.currentUser();
  const history = useNavigate();

  const getUserName = () => {
    if (currentUser) {
      return currentUser.displayName;
    }
    return "";
  };
  const dispalyNameFromProfile = getUserName();
  const onLogoutClick = (e: React.MouseEvent) => {
    e.preventDefault();
    firebaseModules
      .auth()
      .signOut()
      .then(function () {
        history("/login");
        window.location.reload();
      })
      .catch(function () {});
  };
  const clickContent = (
    <div className="navbar-profile-info-container">
      <div className="navbar-profile-info-header-container">
        <div style={{ minWidth: "4rem", margin: "0 10px" }}>
          <img src="/images/profileIcon.svg" alt="" />
          <img
            src="/images/Ellipse 8.png"
            alt=""
            style={{ position: "absolute", top: "52px", left: "63px" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {dispalyNameFromProfile && (
            <label className="navbar-profile-info-name">
              {dispalyNameFromProfile}
            </label>
          )}
          {currentUser?.email && (
            <label className="navbar-profile-info-email">
              {currentUser?.email}
            </label>
          )}
        </div>
      </div>
      <span className="navbar-profile-info-seperator" />
      <div className="navbar-profile-info-signinAs-container">
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label className="signInAs-header-label">Signed in as</label>
          <label className="signInAs-header-value">Partner</label>
        </div>
      </div>
      <span className="navbar-profile-info-seperator" />
      <div className="logout-container">
        <img
          src="/images/logoutnav.svg"
          alt=""
          style={{ cursor: "pointer", marginTop: "5px" }}
        />
        <label className="logout-label" onClick={onLogoutClick}>
          Log out
        </label>
      </div>
    </div>
  );
  const hoverContent = (
    <div className="navbar-profile-info-container">
      <div className="navbar-profile-info-header-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {dispalyNameFromProfile && (
            <label className="navbar-profile-info-name">
              {dispalyNameFromProfile}
            </label>
          )}
          {currentUser?.email && (
            <label className="navbar-profile-info-email">
              {currentUser?.email}
            </label>
          )}
        </div>
      </div>
    </div>
  );
  const onLoginClick = (e: React.MouseEvent) => {
    e.preventDefault();
    history("/login");
  };
  return (
    <div className="navbar-right-container">
      {!currentUser && (
        <div className="profile-info-wrapper">
          <a href="#contactUs" className="navbar-item">
            Contact Us
          </a>
          <div className="navbar-item">
            <div className="buttons">
              <a className="button is-light" onClick={onLoginClick} href="/">
                Log in
              </a>
            </div>
          </div>
        </div>
      )}
      {currentUser && (
        <div className="profile-info-wrapper">
          <PartnerTag />
          <Popover
            placement="bottomRight"
            content={hoverContent}
            overlayStyle={{ minWidth: "318px", minHeight: "248px" }}
          >
            <Popover
              placement="bottomRight"
              trigger="click"
              content={clickContent}
              overlayStyle={{ minWidth: "318px", zIndex: "1000001" }}
            >
              <img
                src="/images/profileButton.svg"
                alt=""
                style={{
                  cursor: "pointer",
                  height: "24px",
                  width: "24px",
                  margin: "0 10px",
                }}
              />
            </Popover>
          </Popover>
        </div>
      )}
    </div>
  );
}

export default NavBarRight;
