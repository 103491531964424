import React, { useEffect, useState } from "react";
import { font_family_Poppins_Regular } from "../constants/themeConstants";
type TableListPaginationProps = {
  goToFirstPage: () => void;
  goToLastPage: () => void;
  onPreviousClick: () => void;
  onNextClick: () => void;
  onHandleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  page: number;
  totalPages: number;
};
function TableListPagination(props: TableListPaginationProps) {
  const {
    goToFirstPage,
    goToLastPage,
    onPreviousClick,
    onNextClick,
    onHandleKeyDown,
    page,
    totalPages,
  } = props;
  const [state, setState] = useState<any>(page + 1);
  useEffect(() => {
    setState(page + 1);
  }, [page, setState]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        minWidth: "16.5rem",
        gap: "0.75rem",
        height: "100%",
      }}
    >
      <img
        src="/images/firstPageNav.svg"
        alt=""
        onClick={goToFirstPage}
        style={{ cursor: "pointer" }}
      />
      <img
        src="/images/prevPageNav.svg"
        alt=""
        onClick={onPreviousClick}
        style={{ cursor: "pointer" }}
      />
      <input
        style={{
          width: "3.188rem",
          textAlign: "center",
          border: "0.063rem solid #E8E9EE",
          borderRadius: "0.25rem",
          height: "1.75rem",
          padding: "0 0.25rem 0 0.5rem",
        }}
        value={state}
        onChange={(e) => setState(e.target.value)}
        type="number"
        onKeyDown={onHandleKeyDown}
      />
      <span
        style={{
          fontFamily: font_family_Poppins_Regular,
          fontSize: "0.875rem",
          color: "#71777B",
        }}
      >
        of {totalPages}
      </span>
      <img
        src="/images/nextPageNav.svg"
        alt=""
        onClick={onNextClick}
        style={{ cursor: "pointer" }}
      />
      <img
        src="/images/lastPageNav.svg"
        alt=""
        onClick={goToLastPage}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
}

export default TableListPagination;
