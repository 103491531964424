import React from "react";

function DebitTag() {
  return (
    <div
      className="tag-container"
      style={{ backgroundColor: "#FADCDA", color: "#F94948" }}
    >
      <img src="/images/arrowUpRight.svg" alt="" />
      Debit
    </div>
  );
}

export default DebitTag;
