import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics();
// const auth = getAuth();
const analyticsObj = {
  logEvent: (eventName) => logEvent(analytics, eventName),
};
const auth = getAuth();
const fireRealDb = getDatabase(app);
const firebaseModules = {
  app,
  db,
  fireRealDb,
  auth: getAuth,
  currentUser: () => getAuth().currentUser,
  initializeIfRequired: () => {},
  analytics: () => analyticsObj,
  signInWithEmailLink: (email, link) => signInWithEmailLink(auth, email, link),
  isSignInWithEmailLink: (link) => isSignInWithEmailLink(auth, link),
  setPersistenceLocal: () => auth.setPersistence("local"),
  sendSignInLinkToEmail: (email, actionCodeSettings) =>
    sendSignInLinkToEmail(auth, email, actionCodeSettings),
};

export default firebaseModules;
