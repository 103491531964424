import React from "react";
import { useSidebarStore } from "../../src/store/sidebarStore";
import NavbarNew from "./components/NavbarNew";
import SideBar from "./components/SideBar";

export type LayoutProps = {
  children: React.ReactNode;
  breadCrumb?: any;
};

export default function LayoutV2(props: LayoutProps) {
  const { children, breadCrumb } = props;
  const sidebarWidth = useSidebarStore((state) => state.sidebarWidth);
  return (
    <>
      <NavbarNew breadcrub={breadCrumb} />
      <div className="sidebar-main-container">
        <SideBar />
        <div
          className="main-container-v2"
          style={{ minWidth: `calc(100vw - ${sidebarWidth})` }}
        >
          <main>
            <div className="sidebar-main-children-container">{children}</div>
          </main>
        </div>
      </div>
    </>
  );
}
