import create from "zustand";
export type CountDto = {
  count: number;
};
export type RevenueDto = {
  currency: string;
  amount: number;
  transactionCount: number;
};
type StoreType = {
  countDto: CountDto | null;
  revenueDto: RevenueDto | null;
  setCountDto: (countDto: CountDto) => void;
  setRevenueDto: (revenueDto: RevenueDto) => void;
};
export const usePartnerStore = create<StoreType>((set) => ({
  countDto: null,
  revenueDto: null,
  setCountDto: (countDto: CountDto) =>
    set(() => {
      return { countDto: countDto };
    }),
  setRevenueDto: (revenueDto: RevenueDto) =>
    set(() => {
      return { revenueDto: revenueDto };
    }),
}));
