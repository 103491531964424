import {
  getNewReferralCount,
  getPartnerReferals,
  getPartnerTransactions,
  getRevenue,
} from "../clients/partnersClients";
import { getUserProfile } from "../clients/userProfileClients";

export const fetchUserProfile = (showToast, setUserProfile) => {
  getUserProfile(showToast).then((response) => {
    if (response) {
      setUserProfile(response);
    }
  });
};
export const fetchTransactions = (
  pageNumber,
  size,
  sortBy,
  sortOrder,
  setTransactions,
  setTotalPages,
  setPageNumber,
  showLoader,
  showToast
) => {
  const body = {
    pageNo: pageNumber,
    pageSize: size,
    sortBy: sortBy,
    sortOrder: sortOrder,
  };
  showLoader(true);
  getPartnerTransactions(body, showToast).then((response) => {
    showLoader(false);
    if (response) {
      setTransactions(response.content);
      setTotalPages(response.totalPages);
      if (pageNumber > response.totalPages - 1) {
        setPageNumber(0);
      }
    }
  });
};
export const fetchReferrals = (
  pageNumber,
  size,
  sortBy,
  sortOrder,
  setReferrals,
  setTotalPages,
  setPageNumber,
  showLoader,
  showToast
) => {
  const body = {
    pageNo: pageNumber,
    pageSize: size,
    sortBy: sortBy,
    sortOrder: sortOrder,
  };
  showLoader(true);
  getPartnerReferals(body, showToast).then((response) => {
    showLoader(false);
    if (response) {
      setReferrals(response.content);
      setTotalPages(response.totalPages);
      if (pageNumber > response.totalPages - 1) {
        setPageNumber(0);
      }
    }
  });
};
export const fetchNewReferralCountDto = (showToast, setCountDto, body) => {
  getNewReferralCount(body, showToast).then((response) => {
    if (response) {
      setCountDto(response);
    }
  });
};
export const fetchRevenueDto = (showToast, setRevenueDto, body) => {
  getRevenue(body, showToast).then((response) => {
    if (response) {
      setRevenueDto(response);
    }
  });
};
