import create from "zustand";

export type useApiLoaderStoreType = {
  show: boolean;
  showLoader: (param: boolean) => void;
};
export const useApiLoaderStore = create<useApiLoaderStoreType>((set) => {
  return {
    show: false,

    showLoader: (param: boolean) => {
      set(() => ({
        show: param,
      }));
    },
  };
});
