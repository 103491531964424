import React, { useState } from "react";
import ManageSubscriptionsDrawer from "../dashboard/ManageSubscriptionsDrawer";
import { userProfileStore } from "../../store/userProfileStore";
function TransactionUpperActionBar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const userProfile = userProfileStore((state) => state.userProfile);
  function getCurrencyAndAmount() {
    if (userProfile) {
      const balance = userProfile.partnerAccount.balance;
      return userProfile.partnerAccount.currency === "INR" ? (
        <>&#8377;{balance}</>
      ) : (
        <>${balance}</>
      );
    }
  }
  return (
    <div className="transaction-upper-actionbar-container">
      {isDrawerOpen && (
        <ManageSubscriptionsDrawer
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
        />
      )}
      <span className="transaction-upper-actionbar-header">Transactions</span>
      <div className="flex-align-center gap-1rem">
        <button
          className="button-v2 disable-btn"
          disabled
          style={{ cursor: "auto" }}
        >
          Available credits : {getCurrencyAndAmount()}
        </button>
        <button className="button-v2" onClick={() => setIsDrawerOpen(true)}>
          Manage subscriptions
        </button>
      </div>
    </div>
  );
}

export default TransactionUpperActionBar;
