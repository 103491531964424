/* eslint-disable jsx-a11y/anchor-is-valid */
import { Drawer } from "antd";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { userProfileStore } from "../../store/userProfileStore";
import { getUserProfile } from "../../clients/userProfileClients";
import { useToastStore } from "../../store/toastStore";
import firebaseModules from "../../firebase/firebase";
import NavBarRight from "./NavBarRight";
import SideBar from "./SideBar";
export const blobTextToUrl = (blobText: any) => {
  const blob = new Blob([blobText], { type: "image/svg+xml" });
  const blobUrl = URL.createObjectURL(blob);
  return blobUrl;
};
type NavbarProps = {
  breadcrub?: any;
};
function NavbarNew(props: NavbarProps) {
  const { breadcrub } = props;
  const [isSidebarDrawerClose, setSidebarDrawerClose] = useState(false);
  const showToast = useToastStore((state) => state.showToast);
  const history = useNavigate();
  const [userProfile, setUserProfile] = userProfileStore((state) => [
    state.userProfile,
    state.setUserProfile,
  ]);
  const currentUser = firebaseModules.currentUser();
  useEffect(() => {
    if (currentUser && !userProfile) {
      getUserProfile(showToast).then((response: any) => {
        if (response) {
          setUserProfile(response);
        }
      });
    }
  }, [currentUser, userProfile, setUserProfile, showToast]);
  useEffect(() => {
    if (userProfile && userProfile.registered === false) {
      history("/");
    }
  }, [userProfile, history]);

  return (
    <div className="navbar min-w-100">
      <nav className="menuBar">
        <div className="barsMenu">
          <a
            className="navbar-burger"
            role="button"
            style={{ margin: 0 }}
            onClick={() => setSidebarDrawerClose(true)}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="logo">
            <img src="/images/EvalBeeBlue.svg" alt="" />
            <label className="navbar-logo-label">EVALBEE</label>
          </div>
          {breadcrub && (
            <div className="navbar-breadcrum-container">{breadcrub}</div>
          )}
        </div>
        <div className="menuCon">
          <div className="rightMenu">
            <NavBarRight />
          </div>
        </div>
      </nav>
      <Drawer
        placement="left"
        onClose={() => setSidebarDrawerClose(false)}
        open={isSidebarDrawerClose}
        bodyStyle={{ padding: "0px" }}
        headerStyle={{ display: "none" }}
        contentWrapperStyle={{ width: "15rem" }}
        maskStyle={{ background: "rgba(0, 0, 0, 0.1)" }}
      >
        <div className="drawer-left-sidebar-container">
          <SideBar
            isSidebarDrawerClose={isSidebarDrawerClose}
            setSidebarDrawerClose={setSidebarDrawerClose}
          />
        </div>
      </Drawer>
    </div>
  );
}

export default NavbarNew;
