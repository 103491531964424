// export const primaryColor = "#009bae";
export const primaryColor = "#2467C6";
export const primaryColor_300 = "#579AF9";
export const primaryColor_100 = "#dbe4f0";
export const nutral100 = "#e8e9ee";
export const grayColor = "#71777B";
export const whiteColor = "white";
export const darkYellowColor = "#FFCC00";
export const oasisSandColor = "#fcedc4";
export const whisperBlueColor = "#e6e9f2";
export const systeamGreenColor = "#439D62";
export const systeamRedColor = "#F94948";
export const systeamYellowColor = "#F0C20B";
export const systeamBlueColor = "#2467C6";
export const redColor = "red";
export const text_color_400 = "#71777b";
export const font_family_DmSans_Regular = "DmSans-Regular";
export const font_family_DmSans_Bold = "DmSans-Bold";
export const font_family_DmSans_Medium = "DmSans-Medium";
export const font_family_Poppins_Regular = "Poppins-Regular";
export const font_family_Poppins_SemiBold = "Poppins-SemiBold";
export const font_family_Poppins_Bold = "Poppins-Bold";
export const background_color = "#f2f4f8";
