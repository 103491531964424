import React, { Suspense, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Loader from "./common/Loader";
import firebaseModules from "./firebase/firebase";
import PrivateRoute from "./common/PrivateRoute";
import Transactions from "./pages/transctions/Transactions";
import ModalContainer from "./common/ModalContainer";
const LandingPage = React.lazy(() => import("./pages/LandingPage"));
const Login = React.lazy(() => import("./pages/login/Login"));
const LoginVerify = React.lazy(() => import("./pages/login/LoginVerify"));
const Dashboard = React.lazy(() => import("./pages/dashboard/Dashboard"));
const Settings = React.lazy(() => import("./pages/settings/Settings"));

function App() {
  const [loading, setLoading] = useState(true);

  firebaseModules.initializeIfRequired();
  const auth = getAuth();
  onAuthStateChanged(auth, () => {
    setLoading(false);
  });

  if (loading) {
    return <Loader />;
  }
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <ModalContainer />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verify" element={<LoginVerify />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/transactions"
            element={
              <PrivateRoute>
                <Transactions />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<LandingPage />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
