import { ShowToastType, get, post, postWithJsonResponse } from "./fetchUtils";

export type ReferralAddDto = {
  email: string;
  phoneNo: string;
  displayName: string;
};
type FilterParams = {
  pageNo: number;
  pageSize: number;
  sortBy: string;
  sortOrder: string;
};
export type PostVerifyPaymentRequest = {
  orderId: string;
  paymentId: string;
};
export type PostPaymentOrderRequest = {
  currency: string;
  price: number;
};
export type SubscriptionUpdateDto = {
  userUid: string;
  productType: string;
  teacherCount: number;
  isRenew: boolean;
};
type NewReferralCountDto = {
  lowerMillis: number;
  upperMillis: number;
};
export const postUpdateReferal = (
  referralAddDto: ReferralAddDto,
  showToast: ShowToastType
) => {
  return post(`api-ums/web/partner/new-referral`, referralAddDto, showToast);
};
export const getPartnerReferals = (
  filter: FilterParams,
  showToast: ShowToastType
) => {
  return get(`api-ums/web/partner/referrals`, filter, showToast);
};
export const postPaymentOrder = (
  param: PostPaymentOrderRequest,
  showToast: ShowToastType
) => {
  return postWithJsonResponse(
    `api-ums/web/partner/payment/purchase-order`,
    param,
    showToast
  );
};
export const postVerifyPayment = (
  param: PostVerifyPaymentRequest,
  showToast: ShowToastType
) => {
  return post(`api-ums/web/partner/payment/verify`, param, showToast);
};
export const getPartnerTransactions = (
  filter: FilterParams,
  showToast: ShowToastType
) => {
  return get(`api-ums/web/partner/transactions`, filter, showToast);
};
export const getVerifyPartnerProfileDto = (
  searchText: string,
  showToast: ShowToastType
) => {
  return get(`api-ums/web/partner/user-verify`, { searchText }, showToast);
};
export const postVerifySubscription = (
  param: SubscriptionUpdateDto,
  showToast: ShowToastType
) => {
  return postWithJsonResponse(
    `api-ums/web/partner/subscription/verify`,
    param,
    showToast
  );
};
export const postSubscriptionUpdate = (
  param: SubscriptionUpdateDto,
  showToast: ShowToastType
) => {
  return postWithJsonResponse(
    `api-ums/web/partner/subscription/update`,
    param,
    showToast
  );
};
export const getNewReferralCount = (
  param: NewReferralCountDto,
  showToast: ShowToastType
) => {
  return get(`api-ums/web/partner/new-referral-count`, param, showToast);
};
export const getRevenue = (
  param: NewReferralCountDto,
  showToast: ShowToastType
) => {
  return get(`api-ums/web/partner/revenue`, param, showToast);
};
export const getPartnerDiscountsDto = (showToast: ShowToastType) => {
  return get(`api-ums/web/partner/discounts`, {}, showToast);
};
