import firebaseModules from "../firebase/firebase";

const API_URL = process.env.REACT_APP_API_URL;
const getBaseUrl = (url: string) => API_URL;

const queryString = (params: { [key: string]: string }) => {
  if (Object.keys(params).length === 0) return "";
  let queryStr =
    "?" +
    Object.keys(params)
      .reduce(function (acc: string[], k: string) {
        acc.push(k + "=" + encodeURIComponent(params[k] as string));
        return acc;
      }, [])
      .join("&");
  return queryStr;
};

export const get = (
  url: string,
  queryParams = {},
  showToast?: ShowToastType
) => {
  const currentUser = firebaseModules.currentUser();
  return currentUser!
    .getIdToken()
    .then((token) => {
      return fetch(`${getBaseUrl(url)}/${url}${queryString(queryParams)}`, {
        method: "GET",
        headers: {
          "x-user-token": token,
        },
      });
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 400) {
        return response.json();
      }
      showApiErrorToast(response, showToast);
      return false;
    })
    .catch(() => {
      alert(
        "Something went wrong. Please contact support or try again in sometime."
      );
      return false;
    });
};
export const postFile = (
  url: string,
  bodyObject?: any,
  queryParams = {},
  showToast?: ShowToastType
) => {
  const currentUser = firebaseModules.currentUser();
  return currentUser!
    .getIdToken()
    .then((token) => {
      return fetch(`${getBaseUrl(url)}/${url}${queryString(queryParams)}`, {
        method: "POST",
        headers: {
          "x-user-token": token,
        },
        body: bodyObject,
      });
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 400) {
        return response;
      }
      showApiErrorToast(response, showToast);
      return false;
    })

    .catch(() => {
      alert(
        "Something went wrong. Please contact support or try again in sometime."
      );
      return false;
    });
};

export const getFile = (
  url: string,
  queryParams = {},
  showToast?: ShowToastType
) => {
  const currentUser = firebaseModules.currentUser();
  return currentUser!.getIdToken().then((token) => {
    return fetch(`${getBaseUrl(url)}/${url}${queryString(queryParams)}`, {
      method: "GET",
      headers: {
        "x-user-token": token,
      },
    }).then((response: any) => {
      if (response.status >= 200 && response.status < 400) {
        return response;
      }
      showApiErrorToast(response, showToast);
      return false;
    });
  });
};
export const post = (
  url: string,
  bodyObject: object,
  showToast?: ShowToastType
) => {
  const currentUser = firebaseModules.currentUser();
  return currentUser!
    .getIdToken()
    .then((token) => {
      return fetch(`${getBaseUrl(url)}/${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-user-token": token,
        },
        body: JSON.stringify(bodyObject),
      });
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 400) {
        if (parseInt(response.headers.get("content-length")!) > 0) {
          return response.json();
        }
        return true;
      }
      showApiErrorToast(response, showToast);
      return false;
    })
    .catch(() => {
      alert(
        "Something went wrong. Please contact support or try again in sometime."
      );
      return false;
    });
};
export const postWithJsonResponse = (
  url: string,
  bodyObject: object,
  showToast?: ShowToastType
) => {
  const currentUser = firebaseModules.currentUser();
  return currentUser!
    .getIdToken()
    .then((token) => {
      return fetch(`${getBaseUrl(url)}/${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-user-token": token,
        },
        body: JSON.stringify(bodyObject),
      });
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 400) {
        return response.json();
      }
      showApiErrorToast(response, showToast);
      return false;
    })
    .catch(() => {
      alert(
        "Something went wrong. Please contact support or try again in sometime."
      );
      return false;
    });
};
export const put = (
  url: string,
  bodyObject: object,
  showToast?: ShowToastType
) => {
  const currentUser = firebaseModules.currentUser();
  return currentUser!
    .getIdToken()
    .then((token) => {
      return fetch(`${getBaseUrl(url)}/${url}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-user-token": token,
        },
        body: JSON.stringify(bodyObject),
      });
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 400) {
        if (parseInt(response.headers.get("content-length")!) > 0) {
          return response.json();
        }
        return true;
      }
      showApiErrorToast(response, showToast);
      return false;
    })
    .catch(() => {
      alert(
        "Something went wrong. Please contact support or try again in sometime."
      );
      return false;
    });
};

export const deleteApi = (
  url: string,
  bodyObject = {},
  showToast?: ShowToastType
) => {
  const currentUser = firebaseModules.currentUser();
  return currentUser!
    .getIdToken()
    .then((token) => {
      return fetch(`${getBaseUrl(url)}/${url}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-user-token": token,
        },
        body: JSON.stringify(bodyObject),
      });
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 400) {
        return true;
      }
      showApiErrorToast(response, showToast);
      return false;
    })
    .catch(() => {
      alert(
        "Something went wrong. Please contact support or try again in sometime."
      );
      return false;
    });
};
export type ShowToastType = (showToast: any) => void;
export type ShowLoaderType = (showLoader: any) => void;
export const showApiErrorToast = (response: any, showToast?: ShowToastType) => {
  if (response.status === 418) {
    response.json().then((errorResopnse: any) => {
      errorResopnse &&
        showToast &&
        showToast({
          theme: "error",
          message: errorResopnse.message,
          title: "Error",
          autoClose: false,
        });
    });
  } else {
    showToast &&
      showToast({
        theme: "error",
        message: "Something went wrong please try again",
        title: "Error",
        autoClose: true,
      });
  }
};
