import { ShowToastType, get, post } from "./fetchUtils";
type PartnerProfileUpdateDto = {
  phoneNo: string;
  displayName: string;
  orgName: string;
};
export const getUserProfile = (showToast: ShowToastType) => {
  return get(`api-ums/web/partner/profile`, {}, showToast);
};
export const postUpdateProfile = (
  body: PartnerProfileUpdateDto,
  showToast: ShowToastType
) => {
  return post(`api-ums/web/partner/profile`, body, showToast);
};
