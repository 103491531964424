import create from "zustand";
type Referrals = {
  uid: string;
  partnerOrgId: string;
  email: string;
  phoneNo: string;
  displayName: string;
  updatedOn: number;
};
type StoreType = {
  referrals: Referrals[] | null;
  pageNumber: number;
  size: number;
  totalPages: number;
  sortBy: string;
  sortOrder: string;
  setReferrals: (referrals: Referrals[]) => void;
  setPageNumber: (pageNumber: number) => void;
  setSize: (size: number) => void;
  setTotalPages: (totalPages: number) => void;
  setSortBy: (param: string) => void;
  setSortOrder: (param: string) => void;
};
export const useReferralStore = create<StoreType>((set) => ({
  referrals: null,
  pageNumber: 0,
  size: 50,
  totalPages: 0,
  sortBy: "updatedOn",
  sortOrder: "DESC",
  setReferrals: (referrals: Referrals[]) =>
    set(() => {
      return { referrals: referrals };
    }),
  setSortBy: (state: string) =>
    set(() => {
      return { sortBy: state };
    }),
  setSortOrder: (state: string) =>
    set(() => {
      return { sortOrder: state };
    }),
  setPageNumber: (state: number) =>
    set(() => {
      return { pageNumber: state };
    }),
  setSize: (state: number) =>
    set(() => {
      return { size: state };
    }),
  setTotalPages: (state: number) =>
    set(() => {
      return { totalPages: state };
    }),
}));
