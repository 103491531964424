import create from "zustand";

export type useSidebarStoreType = {
  sidebarWidth: string;
  isSidebarClose: boolean;
  showInfoCard: boolean;
  setSidebarWidth: (width: string) => void;
  setIsSidebarClose: (param: boolean) => void;
  setShowInfoCard: (param: boolean) => void;
};
export const useSidebarStore = create<useSidebarStoreType>((set) => {
  return {
    sidebarWidth: "15rem",
    isSidebarClose: false,
    showInfoCard: true,

    setSidebarWidth: (width: string) => {
      set(() => ({
        sidebarWidth: width,
      }));
    },
    setIsSidebarClose: (param: boolean) => {
      set(() => ({
        isSidebarClose: param,
      }));
    },
    setShowInfoCard: (param: boolean) => {
      set(() => ({
        showInfoCard: param,
      }));
    },
  };
});
