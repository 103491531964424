import React from "react";
export default function Loader() {
  return (
    <div className="spinner-wrapper">
      <div className="spinner"></div>
      <div className="content">
        <h1>Loading...</h1>
      </div>
    </div>
  );
}
