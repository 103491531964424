import React from "react";
import Modal from "react-modal";
import { useModalStore } from "../store/modalStore";
import customStyles from "./ModalCustomStyles";

function ModalContainer() {
  const [show, content, closeModal] = useModalStore((state) => [
    state.isOpen,
    state.content,
    state.closeModal,
  ]);

  return (
    <>
      {show && (
        <Modal
          isOpen={show}
          style={customStyles}
          contentLabel="Modal"
          onRequestClose={closeModal}
          shouldCloseOnOverlayClick={false}
          overlayClassName="modal-overlay"
          ariaHideApp={false}
        >
          {content}
        </Modal>
      )}
    </>
  );
}

export default ModalContainer;
