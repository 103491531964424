export function getFormattedDateAndTime(date) {
  var dateObject = new Date(date);
  var year = dateObject.getFullYear();
  var month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
  var day = ("0" + dateObject.getDate()).slice(-2);
  var hours = ("0" + dateObject.getHours()).slice(-2);
  var minutes = ("0" + dateObject.getMinutes()).slice(-2);
  var formattedDateTime =
    day + "-" + month + "-" + year + " " + hours + ":" + minutes;
  return formattedDateTime;
}
