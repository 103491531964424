import create from "zustand";
type Transaction = {
  id: number;
  orgId: string;
  userId: string;
  change: number;
  balance: number;
  currency: string;
  orderId: string;
  details: string;
  createdOn: number;
};
export type PartnerSubscriptionUpdateResponse = {
  email: string;
  uid: string;
  productType: string;
  teacherAccount: number;
  expiryMinutes: number;
};
type StoreType = {
  transactions: Transaction[] | null;
  pageNumber: number;
  size: number;
  totalPages: number;
  sortBy: string;
  sortOrder: string;
  partnerSubscriptionUpdateResponse: PartnerSubscriptionUpdateResponse | null;
  setTransactions: (transactions: Transaction[]) => void;
  setPageNumber: (pageNumber: number) => void;
  setSize: (size: number) => void;
  setTotalPages: (totalPages: number) => void;
  setSortBy: (param: string) => void;
  setSortOrder: (param: string) => void;
  setPartnerSubscriptionUpdateResponse: (
    partnerSubscriptionUpdateResponse: PartnerSubscriptionUpdateResponse
  ) => void;
};
export const useTransctionStore = create<StoreType>((set) => ({
  transactions: null,
  pageNumber: 0,
  size: 50,
  totalPages: 0,
  sortBy: "createdOn",
  sortOrder: "DESC",
  partnerSubscriptionUpdateResponse: null,
  setTransactions: (transactions: Transaction[]) =>
    set(() => {
      return { transactions: transactions };
    }),
  setSortBy: (state: string) =>
    set(() => {
      return { sortBy: state };
    }),
  setSortOrder: (state: string) =>
    set(() => {
      return { sortOrder: state };
    }),
  setPageNumber: (state: number) =>
    set(() => {
      return { pageNumber: state };
    }),
  setSize: (state: number) =>
    set(() => {
      return { size: state };
    }),
  setTotalPages: (state: number) =>
    set(() => {
      return { totalPages: state };
    }),
  setPartnerSubscriptionUpdateResponse: (
    partnerSubscriptionUpdateResponse: PartnerSubscriptionUpdateResponse
  ) =>
    set(() => {
      return {
        partnerSubscriptionUpdateResponse: partnerSubscriptionUpdateResponse,
      };
    }),
}));
