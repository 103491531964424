import create from "zustand";
export type PartnerProfile = {
  uid: string;
  email: string;
  phoneNo: string;
  displayName: string;
  orgId: string;
  orgName: string;
};
export type PartnerAccount = {
  partnerId: string;
  balance: number;
  currency: string;
  updatedOn: number;
};
export type UserProfile = {
  registered: boolean;
  partnerProfile: PartnerProfile;
  partnerAccount: PartnerAccount;
};

export type StoreType = {
  userProfile: UserProfile | null;
  setUserProfile: (profile: UserProfile) => void;
};
export const userProfileStore = create<StoreType>((set) => {
  return {
    userProfile: null,

    setUserProfile: (profile: UserProfile) => {
      set(() => ({
        userProfile: profile,
      }));
    },
  };
});
