import create from "zustand";

const initialState = {
  show: false,
  theme: "primary",
  message: "",
  title: "Attention",
};

export const useToastStore = create((set) => {
  const hideToast = () => {
    set(() => ({
      ...initialState,
    }));
  };
  return {
    ...initialState,
    hideToast,
    showToast: ({
      theme,
      message,
      title,
      autoClose = false,
      timeout = 3000,
      hidePrevious = true,
    }) => {
      if (message) {
        hidePrevious && hideToast();
        setTimeout(() => {
          set((state) => ({
            ...state,
            theme,
            title,
            message,
            show: true,
          }));
          if (autoClose) {
            setTimeout(hideToast, timeout);
          }
        }, 100);
      }
    },
  };
});
