import React from "react";
import { getFormattedDateAndTime } from "../../helper/utils";
type Props = {
  cell: any;
};
function ReferralsTableUpdatedOn(props: Props) {
  const { cell } = props;
  return <>{getFormattedDateAndTime(cell.row.original.updatedOn)}</>;
}

export default ReferralsTableUpdatedOn;
