import { CircularProgress } from "@material-ui/core";
import React from "react";
import ReactDOM from "react-dom";
import { primaryColor } from "../constants/themeConstants";
import { classNames } from "../helper/classNames";
import { useApiLoaderStore } from "../store/apiLoderStore";

function ApiLoaderContent() {
  const { show } = useApiLoaderStore((state) => state);
  document.getElementById("api-loader-container")!.style.zIndex = show
    ? "10000"
    : "-1";
  return show ? (
    <article
      className={classNames({
        show: show,
        hide: !show,
      })}
    >
      <CircularProgress style={{ color: primaryColor }} />
    </article>
  ) : (
    <></>
  );
}
export default function ApiLoader() {
  return ReactDOM.createPortal(
    <ApiLoaderContent />,
    document.getElementById("api-loader-container")!
  );
}
