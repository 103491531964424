import React, { useCallback, useEffect } from "react";
import { useLocalStorage } from "../../helper/hooks";
import LayoutV2 from "../../layout/layoutV2";
import { useTransctionStore } from "../../store/transactionsStore";
import Table from "../../common/table";
import { useApiLoaderStore } from "../../store/apiLoderStore";
import { useToastStore } from "../../store/toastStore";
import TableListPagination from "../../common/TableListPagination";
import { transactionTableHeaders } from "../../constants/tableHeaders";
import { fetchTransactions } from "../../helper/apiUtils";
import TransactionUpperActionBar from "./TransactionUpperActionBar";

function Transactions() {
  const [sidebarTab, setSidebarTab] = useLocalStorage(
    "sidebarTab",
    "Transactions"
  );
  if (sidebarTab !== "Transactions") {
    setSidebarTab("Transactions");
  }
  const showLoader = useApiLoaderStore((state) => state.showLoader);
  const showToast = useToastStore((state) => state.showToast);
  const [
    transactions,
    setTransactions,
    pageNumber,
    setPageNumber,
    size,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    totalPages,
    setTotalPages,
  ] = useTransctionStore((state) => [
    state.transactions,
    state.setTransactions,
    state.pageNumber,
    state.setPageNumber,
    state.size,
    state.sortBy,
    state.setSortBy,
    state.sortOrder,
    state.setSortOrder,
    state.totalPages,
    state.setTotalPages,
  ]);
  useEffect(() => {
    fetchTransactions(
      pageNumber,
      size,
      sortBy,
      sortOrder,
      setTransactions,
      setTotalPages,
      setPageNumber,
      showLoader,
      showToast
    );
  }, [
    showLoader,
    showToast,
    pageNumber,
    size,
    sortBy,
    sortOrder,
    setTransactions,
    setPageNumber,
    setTotalPages,
  ]);
  const onSort = useCallback(
    (sortBy: string, sortOrder: string) => {
      setSortBy(sortBy);
      setSortOrder(sortOrder);
    },
    [setSortBy, setSortOrder]
  );
  const onHandleKeyDown = useCallback(
    (e: any) => {
      if (e.key === "Enter") {
        if (parseInt(e.currentTarget.value) <= 0) {
          setPageNumber(0);
        } else {
          setPageNumber(parseInt(e.currentTarget.value) - 1);
        }
      }
    },
    [setPageNumber]
  );
  return (
    <LayoutV2>
      <div className="transaction-container">
        <TransactionUpperActionBar />
        {transactions && (
          <div className="dashboard-referals-table-container">
            <div className="dashboard-referals-table">
              <Table
                columns={transactionTableHeaders}
                data={transactions}
                className="is-striped is-hoverable"
                isSortable={false}
                currentSortKey={sortBy}
                currentSortOrder={sortOrder}
                onSort={(sortBy: string, sortOrder: string) =>
                  onSort(sortBy, sortOrder)
                }
              />
            </div>
            <div className="p-1rem">
              <TableListPagination
                goToFirstPage={() => setPageNumber(0)}
                goToLastPage={() => setPageNumber(totalPages - 1)}
                onPreviousClick={() => {
                  if (pageNumber > 0) {
                    setPageNumber(pageNumber - 1);
                  }
                }}
                onNextClick={() => {
                  if (pageNumber < totalPages - 1) {
                    setPageNumber(pageNumber + 1);
                  }
                }}
                onHandleKeyDown={onHandleKeyDown}
                page={pageNumber}
                totalPages={totalPages}
              />
            </div>
          </div>
        )}
      </div>
    </LayoutV2>
  );
}

export default Transactions;
