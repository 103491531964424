import React from "react";
import { useNavigate } from "react-router";
import { primaryColor } from "../../../src/constants/themeConstants";
import { useLocalStorage } from "../../helper/hooks";
import { useSidebarStore } from "../../store/sidebarStore";
type SideBarProps = {
  isSidebarDrawerClose?: boolean;
  setSidebarDrawerClose?: any;
};
export default function SideBar(props: SideBarProps) {
  const { isSidebarDrawerClose, setSidebarDrawerClose } = props;
  const [sidebarTab, setSidebarTab] = useLocalStorage(
    "sidebarTab",
    "Dashboard"
  );
  const [sidebarWidth, setSidebarWidth, isSidebarClose, setIsSidebarClose] =
    useSidebarStore((state) => [
      state.sidebarWidth,
      state.setSidebarWidth,
      state.isSidebarClose,
      state.setIsSidebarClose,
    ]);
  const saveSelectedTab = (Tab: string) => {
    setSidebarTab(Tab);
  };
  const history = useNavigate();
  const isChecked = (tab: string) => {
    return sidebarTab === tab ? "sidebar-item-checked" : "";
  };
  const getBackgroundAndColor = (tab: string) => {
    return sidebarTab === tab
      ? { background: primaryColor, color: "white" }
      : { background: "", color: "" };
  };

  return (
    <div>
      <nav
        className="sidebar"
        style={{
          width: sidebarWidth,
          display: isSidebarDrawerClose ? "flex" : "",
        }}
      >
        <div className="sidebar-item-container">
          <label
            className="sidebar-link-label-container "
            style={{ justifyContent: isSidebarClose ? "center" : "flex-end" }}
          >
            <img
              className="sidebar-image"
              src="/images/closeArrow.svg"
              alt=""
              style={{
                height: "20px",
                width: "20px",
                cursor: "pointer",
                rotate: isSidebarClose ? "180deg" : "",
              }}
              onClick={() => {
                if (isSidebarDrawerClose) {
                  setSidebarDrawerClose(false);
                } else {
                  let temp = isSidebarClose;
                  temp = !temp;
                  if (temp) {
                    setSidebarWidth("5.25rem");
                  } else {
                    setSidebarWidth("15rem");
                  }
                  setIsSidebarClose(temp);
                }
              }}
            />
          </label>
          <label
            className={`sidebar-link-label-container ${isChecked("Dashboard")}`}
            style={{
              justifyContent: isSidebarClose ? "center" : "flex-start",
            }}
            onClick={() => {
              saveSelectedTab("Dashboard");
              history("/dashboard");
            }}
          >
            <img
              className="sidebar-img"
              src={
                sidebarTab === "Dashboard"
                  ? "/images/sidebar-dashboard-selected.svg"
                  : "/images/sidebar-dashboard.svg"
              }
              alt=""
            />
            {!isSidebarClose && (
              <span
                className="sidebar-name-label"
                style={{
                  background: getBackgroundAndColor("Dashboard").background,
                  color: getBackgroundAndColor("Dashboard").color,
                }}
              >
                Dashboard
              </span>
            )}
          </label>
          <label
            className={`sidebar-link-label-container ${isChecked(
              "Transactions"
            )}`}
            style={{
              justifyContent: isSidebarClose ? "center" : "flex-start",
            }}
            onClick={() => {
              saveSelectedTab("Transactions");
              history("/transactions");
            }}
          >
            <img
              className="sidebar-img"
              src={
                sidebarTab === "Transactions"
                  ? "/images/sidebar_transaction_selected.svg"
                  : "/images/sidebar_transaction.svg"
              }
              alt=""
            />
            {!isSidebarClose && (
              <span
                className="sidebar-name-label"
                style={{
                  background: getBackgroundAndColor("Transactions").background,
                  color: getBackgroundAndColor("Transactions").color,
                }}
              >
                Transactions
              </span>
            )}
          </label>
          <label
            className={`sidebar-link-label-container ${isChecked("Settings")}`}
            style={{
              justifyContent: isSidebarClose ? "center" : "flex-start",
            }}
            onClick={() => {
              saveSelectedTab("Settings");
              history("/settings");
            }}
          >
            <img
              className="sidebar-img"
              src={
                sidebarTab === "Settings"
                  ? "/images/sidebar-settings-selected.svg"
                  : "/images/sidebar-settings.svg"
              }
              alt=""
            />
            {!isSidebarClose && (
              <span
                className="sidebar-name-label"
                style={{
                  background: getBackgroundAndColor("Settings").background,
                  color: getBackgroundAndColor("Settings").color,
                }}
              >
                Settings
              </span>
            )}
          </label>
        </div>
      </nav>
    </div>
  );
}
