import { Drawer } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  font_family_Poppins_Regular,
  font_family_Poppins_SemiBold,
} from "../../constants/themeConstants";

import { useApiLoaderStore } from "../../store/apiLoderStore";
import { useToastStore } from "../../store/toastStore";
import {
  getVerifyPartnerProfileDto,
  postVerifySubscription,
} from "../../clients/partnersClients";
import { useModalStore } from "../../store/modalStore";
import { useTransctionStore } from "../../store/transactionsStore";
import {
  fetchNewReferralCountDto,
  fetchReferrals,
  fetchRevenueDto,
  fetchTransactions,
  fetchUserProfile,
} from "../../helper/apiUtils";
import { userProfileStore } from "../../store/userProfileStore";
import { usePartnerStore } from "../../store/partnerStore";
import { useReferralStore } from "../../store/referralsStore";
import SubscriptionUpdateSuccessDialogue from "./SubscriptionUpdateSuccessDialogue";
import UpdateSubscriptionDialogue from "./UpdateSubscriptionDialogue";

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};
type UserVerifyDto = {
  userExists: boolean;
  email: string;
  uid: string;
};
type PayableAmount = {
  currency: string;
  price: string;
};
export type SubscriptionVerifyDto = {
  payableAmount: PayableAmount;
  grossAmount: PayableAmount;
  discountPercentage: number;
};
const PROFESSIONAL = "PROFESSIONAL";
const ENTERPRISE = "ENTERPRISE";
function ManageSubscriptionsDrawer(props: Props) {
  const { isOpen, setIsOpen } = props;
  const showLoader = useApiLoaderStore((state) => state.showLoader);
  const showToast = useToastStore((state) => state.showToast);
  const [searchText, setSearchText] = useState("");
  const [selectedProductType, setSelectedProductType] = useState(PROFESSIONAL);
  const [selectedProductTypeOptions, setSelectedProductTypeOptions] =
    useState<any>([]);
  const [selectedTeacherAccounts, setSelectedTeacherAccounts] = useState<any>();
  const [isRenew, setIsRenew] = useState<any>(false);
  const [userVerifyDto, setUserVerifyDto] = useState<UserVerifyDto | null>(
    null
  );
  const [subscriptionVerifyDto, setSubscriptionVerifyDto] =
    useState<SubscriptionVerifyDto | null>(null);
  const [showModalContent, closeModal] = useModalStore((state) => [
    state.showModalContent,
    state.closeModal,
  ]);
  const [
    setReferrals,
    referralPageNumber,
    setReferralPageNumber,
    referralSize,
    referralSortBy,
    referralSortOrder,
    setReferralTotalPages,
  ] = useReferralStore((state) => [
    state.setReferrals,
    state.pageNumber,
    state.setPageNumber,
    state.size,
    state.sortBy,
    state.sortOrder,
    state.setTotalPages,
  ]);
  const [
    setTransactions,
    pageNumber,
    setPageNumber,
    size,
    sortBy,
    sortOrder,
    setTotalPages,
  ] = useTransctionStore((state) => [
    state.setTransactions,
    state.pageNumber,
    state.setPageNumber,
    state.size,
    state.sortBy,
    state.sortOrder,
    state.setTotalPages,
  ]);
  const setUserProfile = userProfileStore((state) => state.setUserProfile);
  const [setRevenueDto, setCountDto] = usePartnerStore((state) => [
    state.setRevenueDto,
    state.setCountDto,
  ]);
  const onUpdateSubscriptionSuccess = useCallback(() => {
    setIsOpen(false);
    fetchUserProfile(showToast, setUserProfile);
    fetchReferrals(
      referralPageNumber,
      referralSize,
      referralSortBy,
      referralSortOrder,
      setReferrals,
      setReferralTotalPages,
      setReferralPageNumber,
      showLoader,
      showToast
    );
    fetchTransactions(
      pageNumber,
      size,
      sortBy,
      sortOrder,
      setTransactions,
      setTotalPages,
      setPageNumber,
      showLoader,
      showToast
    );
    const params = {
      lowerMillis: new Date().getTime() - 7 * 86400000,
      upperMillis: new Date().getTime(),
    };
    fetchRevenueDto(showToast, setRevenueDto, params);
    fetchNewReferralCountDto(showToast, setCountDto, params);
    showModalContent(
      <SubscriptionUpdateSuccessDialogue closeModal={closeModal} />
    );
  }, [
    setIsOpen,
    closeModal,
    showToast,
    setUserProfile,
    pageNumber,
    size,
    sortBy,
    sortOrder,
    setTransactions,
    setTotalPages,
    setPageNumber,
    setRevenueDto,
    setCountDto,
    showLoader,
    showModalContent,
    referralPageNumber,
    referralSize,
    referralSortBy,
    referralSortOrder,
    setReferrals,
    setReferralTotalPages,
    setReferralPageNumber,
  ]);
  const onUpdateButtonClicked = useCallback(() => {
    if (userVerifyDto) {
      showModalContent(
        <UpdateSubscriptionDialogue
          userUid={userVerifyDto.uid}
          productType={selectedProductType}
          teacherCount={selectedTeacherAccounts}
          isRenew={isRenew}
          closeModal={closeModal}
          onSuccess={onUpdateSubscriptionSuccess}
        />
      );
    }
  }, [
    showModalContent,
    closeModal,
    userVerifyDto,
    isRenew,
    onUpdateSubscriptionSuccess,
    selectedProductType,
    selectedTeacherAccounts,
  ]);
  const onVerifyButtonClicked = useCallback(() => {
    showLoader(true);
    getVerifyPartnerProfileDto(searchText, showToast).then((response) => {
      showLoader(false);
      if (response) {
        setUserVerifyDto(response);
      }
    });
  }, [showLoader, searchText, showToast, setUserVerifyDto]);
  useEffect(() => {
    if (userVerifyDto && userVerifyDto.userExists) {
      const body = {
        userUid: userVerifyDto.uid,
        productType: selectedProductType,
        teacherCount: selectedTeacherAccounts,
        isRenew: isRenew,
      };
      showLoader(true);
      postVerifySubscription(body, showToast).then((response) => {
        showLoader(false);
        if (response) {
          setSubscriptionVerifyDto(response);
        }
      });
    }
  }, [
    userVerifyDto,
    selectedProductType,
    selectedTeacherAccounts,
    isRenew,
    showLoader,
    showToast,
    setSubscriptionVerifyDto,
  ]);
  useEffect(() => {
    let optionArray: any = [];
    if (selectedProductType === PROFESSIONAL) {
      optionArray = new Array(4).fill(0).map((number, index) => (
        <MenuItem value={index + 1} key={index}>
          {index + 1}
        </MenuItem>
      ));
      setSelectedTeacherAccounts(1);
    }
    if (selectedProductType === ENTERPRISE) {
      optionArray = new Array(50)
        .fill(0)
        .map((number, index) => (
          <MenuItem value={index + 1} key={index}>
            {index + 1}
          </MenuItem>
        ))
        .slice(4);
      setSelectedTeacherAccounts(5);
    }
    setSelectedProductTypeOptions(optionArray);
  }, [selectedProductType, setSelectedProductTypeOptions]);
  const getPayableAmount = useCallback(
    (subscriptionVerifyDto: SubscriptionVerifyDto) => {
      return subscriptionVerifyDto.payableAmount.currency === "INR" ? (
        <>&#8377;{subscriptionVerifyDto.payableAmount.price}</>
      ) : (
        <>${subscriptionVerifyDto.payableAmount.price}</>
      );
    },
    []
  );
  const getGrossAmount = useCallback(
    (subscriptionVerifyDto: SubscriptionVerifyDto) => {
      return subscriptionVerifyDto.grossAmount.currency === "INR" ? (
        <>&#8377;{subscriptionVerifyDto.grossAmount.price}</>
      ) : (
        <>${subscriptionVerifyDto.grossAmount.price}</>
      );
    },
    []
  );
  return (
    <Drawer
      title="Manage subscriptions"
      onClose={() => setIsOpen(false)}
      open={isOpen}
      styles={{
        body: { padding: "1rem" },
        wrapper: {
          width: "36rem",
        },
      }}
    >
      <div className="h-100 flex-column">
        <div className="h-100 flex-column gap-1rem">
          <div className="flex-align-center gap-1rem">
            <TextField
              id="outlined-basic"
              label="Email/Uid"
              type="texr"
              variant="outlined"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="w-100"
              required
            />
            <button
              className={
                searchText.length > 1 ? "button-v2" : "button-v2 disable-btn"
              }
              onClick={onVerifyButtonClicked}
            >
              Validate
            </button>
          </div>
          {userVerifyDto && !userVerifyDto.userExists && (
            <span
              className="w-100"
              style={{ textAlign: "center", color: "red" }}
            >
              User not found
            </span>
          )}
          <div className="flex-column h-100">
            {userVerifyDto && userVerifyDto.userExists && (
              <div
                className="flex-column h-100"
                style={{ margin: "1rem 0", gap: "24px" }}
              >
                <span
                  style={{
                    fontFamily: font_family_Poppins_SemiBold,
                    fontSize: "16px",
                  }}
                >
                  Update subscriptions
                </span>
                <div className="manage-subscription-annul-plan-card">
                  <img src="/images/note.svg" alt="" />
                  These are annual plans
                </div>
                <TextField
                  id="outlined-basic"
                  label="User uid"
                  type="texr"
                  variant="outlined"
                  value={userVerifyDto.uid}
                  className="w-100"
                  disabled
                />
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Product type
                  </InputLabel>
                  <Select
                    MenuProps={{
                      style: { maxHeight: "400px", maxWidth: "200px" },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedProductType}
                    label={"Product type"}
                    onChange={(event: any) => {
                      setSelectedProductType(event.target.value);
                    }}
                    required
                  >
                    <MenuItem value={"PROFESSIONAL"} key={0}>
                      Professional
                    </MenuItem>
                    <MenuItem value={"ENTERPRISE"} key={1}>
                      Enterprise
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Teacher accounts
                  </InputLabel>
                  <Select
                    value={selectedTeacherAccounts}
                    MenuProps={{
                      style: { maxHeight: "400px", maxWidth: "200px" },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={"Teacher accounts"}
                    onChange={(event: any) => {
                      setSelectedTeacherAccounts(event.target.value);
                    }}
                    required
                  >
                    {selectedProductTypeOptions}
                  </Select>
                </FormControl>
                {/* <div
                className="flex-align-center gap-1rem"
                style={{ fontFamily: font_family_Poppins_SemiBold }}
              >
                <input
                  type="checkbox"
                  value={isRenew}
                  onChange={() => setIsRenew(!isRenew)}
                  style={{ cursor: "pointer" }}
                />{" "}
                Is renew
              </div> */}
              </div>
            )}
            {userVerifyDto && subscriptionVerifyDto && (
              <div className="manage-subscription-price-container">
                <div
                  className="flex-align-center"
                  style={{ justifyContent: "space-between" }}
                >
                  <span
                    style={{
                      color: "gray",
                      fontFamily: font_family_Poppins_Regular,
                    }}
                  >
                    Gross amount
                  </span>
                  <span
                    style={{
                      fontFamily: font_family_Poppins_SemiBold,
                    }}
                  >
                    {getGrossAmount(subscriptionVerifyDto)}
                  </span>
                </div>
                <div
                  className="flex-align-center"
                  style={{ justifyContent: "space-between" }}
                >
                  <span
                    style={{
                      color: "gray",
                      fontFamily: font_family_Poppins_Regular,
                    }}
                  >
                    Discount
                  </span>
                  <span
                    style={{
                      fontFamily: font_family_Poppins_SemiBold,
                      color: "#439D62",
                    }}
                  >
                    -{subscriptionVerifyDto.discountPercentage}%
                  </span>
                </div>
                <span
                  style={{ border: "1px solid #E8E9EE", display: "flex" }}
                />
                <div
                  className="flex-align-center"
                  style={{ justifyContent: "space-between" }}
                >
                  <span
                    style={{
                      color: "gray",
                      fontFamily: font_family_Poppins_Regular,
                    }}
                  >
                    Net payable amount
                  </span>
                  <span
                    style={{
                      fontFamily: font_family_Poppins_SemiBold,
                      color: "#2467C6",
                    }}
                  >
                    {getPayableAmount(subscriptionVerifyDto)}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className="flex-align-center gap-1rem "
          style={{
            justifyContent: "flex-end",
            borderTop: "1px solid lightGray",
            padding: "1rem 0",
          }}
        >
          <button
            className="button-v2 outline-btn"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </button>
          <button
            className={
              !userVerifyDto || !subscriptionVerifyDto
                ? "button-v2 disable-btn"
                : "button-v2"
            }
            onClick={onUpdateButtonClicked}
            disabled={!userVerifyDto || !subscriptionVerifyDto}
          >
            Update
          </button>
        </div>
      </div>
    </Drawer>
  );
}

export default ManageSubscriptionsDrawer;
