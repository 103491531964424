import React from "react";

export type TableHeaderProps = {
  allSortable: boolean;
  onSort: (columnId: string, currentSortOrder: string) => void;
  currentSortKey: string;
  currentSortOrder: string;
  column: any;
  clgIndex: number;
};

export default function TableHeader(props: TableHeaderProps) {
  const {
    allSortable,
    onSort,
    currentSortKey,
    currentSortOrder,
    column,
    clgIndex,
  } = props;
  const isSortable = allSortable || column.isSortable;
  const isSorted = allSortable
    ? column.isSortable
    : currentSortKey === column.id;
  const isSortedDesc = allSortable
    ? column.isSortedDesc
    : currentSortOrder === "DESC";
  let headerProps = column.getHeaderProps();
  if (isSortable) {
    headerProps = column.getHeaderProps(column.getSortByToggleProps());
  }
  function onHeaderClicked(e: React.MouseEvent) {
    if (
      headerProps &&
      headerProps.onClick &&
      typeof headerProps.onClick === "function"
    ) {
      headerProps.onClick(e);

      if (allSortable) {
        return;
      }

      if (isSortable) {
        if (isSorted) {
          if (isSortedDesc) {
            onSort(column.id, "ASC");
          } else {
            onSort(column.id, "DESC");
          }
        } else {
          onSort(column.id, "DESC");
        }
      }
    }
  }
  if (column.id === "selection")
    return (
      <th {...headerProps} title={column.Header} key={`${clgIndex}`}>
        {column.render("Header")}
      </th>
    );
  return (
    <th
      {...headerProps}
      title={column.Header}
      key={`${clgIndex}`}
      onClick={onHeaderClicked}
    >
      {column.render("Header")}
      {"  "}
      {isSortable && (
        <span>
          {isSorted ? (
            isSortedDesc ? (
              <svg
                viewBox="0 0 15 15"
                fill="none"
                xmlns="https://www.w3.org/2000/svg"
                width="15"
                height="15"
              >
                <path
                  d="M3.5 14.5l-.354.354a.5.5 0 00.708 0L3.5 14.5zm.354.354l3-3-.708-.708-3 3 .708.708zm0-.708l-3-3-.708.708 3 3 .708-.708zM3 0v14.5h1V0H3zm6 4h6V3H9v1zm0 4h4V7H9v1zm0 4h2v-1H9v1z"
                  fill="currentColor"
                ></path>
              </svg>
            ) : (
              <svg
                viewBox="0 0 15 15"
                fill="none"
                xmlns="https://www.w3.org/2000/svg"
                width="15"
                height="15"
              >
                <path
                  d="M3.5.5l.354-.354a.5.5 0 00-.708 0L3.5.5zM3.146.146l-3 3 .708.708 3-3-.708-.708zm0 .708l3 3 .708-.708-3-3-.708.708zM3 .5V15h1V.5H3zM9 4h6V3H9v1zm0 4h4V7H9v1zm0 4h2v-1H9v1z"
                  fill="currentColor"
                ></path>
              </svg>
            )
          ) : (
            <svg
              viewBox="0 0 15 15"
              fill="none"
              xmlns="https://www.w3.org/2000/svg"
              width="15"
              height="15"
            >
              <path
                d="M7.5 1.336L2.17 6h10.66L7.5 1.336zm0 12.328L12.83 9H2.17l5.33 4.664z"
                fill="currentColor"
              ></path>
            </svg>
          )}
        </span>
      )}
    </th>
  );
}
