import React from "react";
import ReactDOM from "react-dom";
import { classNames } from "../../src/helper/classNames";
import { useToastStore } from "../../src/store/toastStore";

function ToastContent() {
  const { message, title, show, theme, hideToast } = useToastStore(
    (state) => state
  );
  document.getElementById("toast-container")!.style.zIndex = show
    ? "10000"
    : "-1";
  function getActionIcon() {
    if (theme === "success") {
      return <img src="/images/circleCheckGreen.svg" alt="" />;
    } else if (theme === "info") {
      return <img src="/images/infoCircle.svg" alt="" />;
    } else if (theme === "warning") {
      return <img src="/images/alertTriangle.svg" alt="" />;
    } else {
      return <img src="/images/infoCircleRed.svg" alt="" />;
    }
  }
  function getCloseIcon() {
    if (theme === "success") {
      return (
        <img
          src="/images/closeToastGreen.svg"
          alt=""
          onClick={hideToast}
          style={{ cursor: "pointer" }}
        />
      );
    } else if (theme === "info") {
      return (
        <img
          src="/images/closeToastBlue.svg"
          alt=""
          onClick={hideToast}
          style={{ cursor: "pointer" }}
        />
      );
    } else if (theme === "warning") {
      return (
        <img
          src="/images/closeToastYellow.svg"
          alt=""
          onClick={hideToast}
          style={{ cursor: "pointer" }}
        />
      );
    } else {
      return (
        <img
          src="/images/closeToastRed.svg"
          alt=""
          onClick={hideToast}
          style={{ cursor: "pointer" }}
        />
      );
    }
  }
  return (
    <article
      className={classNames({
        [`message box p-0`]: true,
        show: show,
        hide: !show,
      })}
    >
      <div
        className={`toast-message-container ${
          theme === "success"
            ? "success"
            : theme === "info"
            ? "info"
            : theme === "warning"
            ? "warning"
            : "error"
        }`}
      >
        <>
          <div
            className="flex-align-center"
            style={{ gap: "0.5rem", width: "100%" }}
          >
            {getActionIcon()}
            <span>{message}</span>
          </div>
          {getCloseIcon()}
        </>
      </div>
    </article>
  );
}
export default function Toast() {
  return ReactDOM.createPortal(
    <ToastContent />,
    document.getElementById("toast-container")!
  );
}
