import React from "react";
import { useTransctionStore } from "../../store/transactionsStore";
type Props = {
  closeModal: () => void;
};
function SubscriptionUpdateSuccessDialogue(props: Props) {
  const { closeModal } = props;
  const partnerSubscriptionUpdateResponse = useTransctionStore(
    (state) => state.partnerSubscriptionUpdateResponse
  );
  return (
    <div className="modal-outer-container">
      <div className="modal-header-container">
        <label className="flex-center header">
          Update subscription success
        </label>
        <img
          src="/images/add-class-x.svg"
          alt=""
          style={{ cursor: "pointer", height: "24px", width: "24px" }}
          onClick={closeModal}
        />
      </div>
      {partnerSubscriptionUpdateResponse && (
        <div className="modal-desc">
          The {partnerSubscriptionUpdateResponse.email} account has active{" "}
          {partnerSubscriptionUpdateResponse.productType} for{" "}
          {partnerSubscriptionUpdateResponse.teacherAccount} teachers account.
        </div>
      )}
      <div className="modal-bottom-actionbar">
        <button className="button-v2 outline-btn" onClick={closeModal}>
          Close
        </button>
      </div>
    </div>
  );
}

export default SubscriptionUpdateSuccessDialogue;
